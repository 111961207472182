.why-choose-section{
    background-color:  #14202c;
}
.whychoose-head {
    text-align: center;
    font-size: 3rem !important;
    color: yellow;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Shadow properties */
  }
  

  .row {
    display: flex;
    flex-wrap: wrap; /* Allow flex items to wrap to the next line */
    justify-content: space-evenly!important; /* Distribute space between the cards */
    margin-left: 10vw;
    margin-right: 10vw;
    margin-top: 3vw;
  }
  
  .card {
    border: 2px solid #e2b42d !important;
    padding: 20px;
    width: 35vw !important; /* Adjust width as needed */
    margin-bottom: 20px;
    background-color: #14202c !important;
    display: flex;
    align-items: start !important;
   
  }
  
  .checkmark {
    font-size: 2rem;
    color: rgb(161, 233, 17);
    margin-right: 40vw;
    margin-top: 3vw;
  
    
  }
  
  .cardtext {
    margin-left: 4vw;
    color: white;
    margin-top: -5vw;
    align-items: start;
    font-size: 1.5rem;
    font-weight: 200;
    text-transform: capitalize;
  }
  
  
  .cardhp {
    margin-bottom: 10px;
    color: white;
    
  }
  .countdownheading{
    color: white;
    font-size: 3rem;
    text-align: center;
    font-family: poppins;
    font-weight: bold;
  }
  
  .Countdown-container{
   display: flex;
   justify-content: center !important;
  }
  .countdown-item{
   background-color: rgb(206, 21, 21);
   height: 10vw;
   width: 10vw;
   border-radius: 1vw;
   display: flex;
   flex-direction: column; /* Arrange items vertically */
   align-items: center; /* Center items horizontally */
   margin: 2vw;
   
  }
  .countdown-digit{
    color: yellow;
    font-size: 4rem;
  }
  .countdown-label{
    color: white;
    font-weight: bold;
    font-size: 1.5rem;
  }
  @keyframes bounce {
    0% { transform: translateY(0); }
    50% { transform: translateY(-10px); }
    100% { transform: translateY(0); }
}
  .arrow{
    color: #f0ec0e;
    font-size: 5rem;
    height: 10vw;
    animation: bounce 1s infinite;
    text-align: center;
  }
  .contact-button {
    position: relative;
    top: -20px; /* Adjust this value as needed */
    background-color: #25d366;
    border-radius: 3vw;
   margin-left: 38vw;
    
  }
  
  .contact-button a {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #25d366; /* WhatsApp green color */
    color: #fff;
    border-radius: 5px;
    padding: 10px 20px;
    text-decoration: none;
    transition: background-color 0.3s ease;
    border-radius: 3vw;
  }
  
  .contact-icon {
    margin-right: 5px;
    font-size: 3rem;
  }
  
  .contact-text {
    font-size: 2rem;
    font-weight: bold;
  }


  @media screen and (max-width: 991px){
    .whychoose-head {
        font-size: 1.5rem !important;
        color: #e6d222; /* Orange */
       
      
    }
    .row {
        display: flex;
        flex-wrap: wrap; /* Allow flex items to wrap to the next line */
        justify-content: space-evenly!important; /* Distribute space between the cards */
        margin-left: 3vh;
        margin-right: 3vh;
        margin-top: 3vh;
      }
      
      .card {
        border: 2px solid #e2b42d !important;
        padding: 20px;
        width: 90vh !important; /* Adjust width as needed */
        margin-bottom: 20px;
        background-color: #14202c !important;
        display: flex;
        align-items: start !important;
       
      }
      
      .checkmark {
        font-size: 2rem;
        color: rgb(161, 233, 17);
        margin-right: 40vw;
        margin-top: 4vh;
      
        
      }
      
      .cardtext {
        margin-left: 6vh;
        color: white;
        margin-top: -10vh;
        align-items: start;
        font-size: 1rem;
        font-weight: 200;
        text-transform: capitalize;
      }
      
      
      .cardhp {
        margin-bottom: 10px;
        color: white;
        
      }
      .countdownheading{
        color: white;
        font-size: 2rem;
        text-align: center;
        font-family: poppins;
      }
      
      .Countdown-container{
       display: flex;
       justify-content: center !important;
      }
      .countdown-item{
       background-color: rgb(206, 21, 21);
       height: 10vh;
       width: 10vh;
       border-radius: 1vw;
       display: flex;
       flex-direction: column; /* Arrange items vertically */
       align-items: center; /* Center items horizontally */
       margin: 2vw;
       
      }
      .countdown-digit{
        color: #e2b42d;
        font-size: 2rem;
      }
      .countdown-label{
        color: white;
        font-weight: bold;
        font-size: 1rem;
      }
      @keyframes bounce {
        0% { transform: translateY(0); }
        50% { transform: translateY(-10px); }
        100% { transform: translateY(0); }
    }
      .arrow{
        color: #e2b42d;
        font-size: 5rem;
        height: 10vw;
        animation: bounce 1s infinite;
        text-align: center;
      }
      .contact-button {
        position: relative;
        margin-top: 10vh;
        background-color: #25d366;
        border-radius: 3vw;
        margin-left: 12vh;
        
      }
      
      .contact-button a {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #25d366; /* WhatsApp green color */
        color: #fff;
        border-radius: 5px;
        padding: 10px 20px;
        text-decoration: none;
        transition: background-color 0.3s ease;
        border-radius: 3vw;
      }
      
      .contact-icon {
        margin-right: 5px;
        font-size: 2rem;
      }
      
      .contact-text {
        font-size: 1rem;
        font-weight: bold;
      }
    
  }
  
