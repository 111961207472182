/* Overall styling for the landing page */
.landing-page {
    text-align: center;
    background-color: #14202c; /* Navy Blue */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 2vw;
    margin: 0;
}
@keyframes blink {
    0% { opacity: 1; }
    50% { opacity: 0; }
    100% { opacity: 1; }
  }
.whatsapp-button {
    display: flex;
    justify-content: center;
  }
  
  .whatsapp-container a {
    display: flex;
    align-items: center;
    background-color: #25d366; /* WhatsApp green color */
    color: #fff;
    border-radius: 5px;
    padding-inline-end: 1vw;
    padding-inline-start: 1vw;
    text-decoration: none;
    animation: blink 3s infinite; /* Apply the blink animation */
    height: 3.5vw;
  }
  
  .whatsapp-container a:hover {
    background-color: #128c7e; /* Darker shade of WhatsApp green on hover */
  }
  
  .whatsapp-icon {
    margin-right: 1vw;
    font-size: 2rem;
  }
  
  .whatsapp-text {
    font-size: 2rem;
    font-weight: bold;
    margin-top: 1vw;
  }
  
  
/* Styling for the container of the offer */
.offer-container {
    background-color: #f1c40f; /* Yellow color */
    padding: 10px;
    margin-top: -2vw;
    margin-left: -2vw;
    margin-right: -2vw;
 
}

/* Styling for the offer text */
.offer-text {
    margin: 0.6vw;
    color: white; /* White text color */
    font-size: 2rem; /* Adjust font size as needed */
    font-weight: bold;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Text shadow with horizontal and vertical offset */
}

.contenthead {
    color: #def10f; /* Accent color */
    font-size: 4rem;
    margin-bottom: 3vw;
    margin-top: 1.5vw;
}

/* Styling for the paragraph text */
.parahead {
    color: white;
    font-size: 1.5rem;
    margin-bottom: 2vw;
    font-weight: bold;
    margin-left: 8vw;
    margin-right: 8vw;
}
.video-row {
    display: flex;
    justify-content: space-evenly;
    margin-top: 3vw;
}

/* Styling for the video container */
.video-container {
    position: relative;
    width: 48%; /* Adjust width to fit two videos in a row */
    height: 25vw;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    overflow: hidden; /* Ensure the video does not overflow its container */
}

/* Styling for the video iframe */
.video-element1{
    width: 100%;
    height: 100%;
    border-radius: 10px;
}
.video-element2 {
    width: 100%;
    height:100%;
    
}

.video-frame {
    width: 100%;
    height:100%;
}

.popup-form {
    position: absolute;
    top: 100%; /* Adjusted top position */
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    width: 90%;
    max-width: 400px;
    text-align: left;
}


.popup-formh2 {
    text-align: center;
    color: #333;
    margin-bottom: 30px;
}

.close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    cursor: pointer;
    color: #999;
}

.close-btn:hover {
    color: #333;
}

.form-group {
    margin-bottom: 20px;
}

.form-label {
    color: #333;
    font-weight: bold;
}

.form-control {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    transition: border-color 0.3s ease;
}

.form-control:focus {
    border-color: #007bff;
    outline: none;
}
.form-select {
    max-width: 11vh; /* Adjust width as needed */
}
.fbutton {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    padding: 10px 20px;
    cursor: pointer;
    width: 100%;
    transition: background-color 0.3s ease;
}

.fbutton:hover {
    background-color: #0056b3;
}

@keyframes bounce {
    0% { transform: translateY(0); }
    50% { transform: translateY(-10px); }
    100% { transform: translateY(0); }
}
.button-link{
    text-decoration: none ;
    color: white;
    font-weight: bold;
    font-size: 2.5rem;
    padding-bottom:4vw ;
    text-transform: uppercase;
    margin-right: 10vw;
    margin-left: 10vw;
}

.formbutton {
    width: 60vw; /* Set button width using viewport width (vw) units */
    background-color: rgb(217, 21, 21) !important;
    border: none !important ;
    color: white !important;
    border-radius: 5px;
    font-size:1rem !important;
    font-weight: bold;
    margin-top: 2vw;
    width: 70vw;
    margin-left: 2vw !important;
    display: block; /* Make the <a> element behave like a block element */
    text-align: center; /* Center the text */
    animation: bounce 1s infinite;
   
   
}

.formbutton:hover {
    background-color: darkred !important; /* Darken the background color on hover */
}
.call-whatsapp-button-symbol{
  font-size: 3rem;
  margin-right: 1vw;
 
}

.photoshead {
    font-size: 4rem;
    color: #f1e60f; /* Accent color */
    margin-top: 30px;
    margin-bottom: 20px;
    text-align: center;
    position: relative;
}

.blessed-line {
    margin-bottom: 0px;
}

.blessed-line p {
    color: #ffffff; /* White */
    font-size: 1.2rem;
    font-style: italic;
}
/* Styling for the container of the heading */
.heading-container {
    border: 2px solid #ebfe3c; /* Gray color */
    border-radius: 5px;
    padding: 10px;
    margin-left: 10vw;
    margin-right: 10vw;
    margin-bottom: 20px; /* Adjust margin as needed */
}

/* Styling for the heading text */
.heading-text {
    margin: 0; /* Remove default margin */
    color: #ebfe3c; /* Gray color */
    font-size: 1.5rem; /* Adjust font size as needed */
}

.connect-with-us {
    background-color: #25d366 !important; /* Yellow */
    color: #ffffff; /* White */
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 1.2rem;
    text-transform: uppercase;
    margin-top: 2vw;
    cursor: pointer;
    text-decoration: none;
    transition: background-color 0.3s ease;
}

.connect-with-us:hover {
    background-color: #d4ac0d; /* Darker shade of yellow */
}


/* Media query for responsiveness on smaller screens */
@media screen and (max-width: 991px) {
    .landing-page {
        background-color: #14202c; /* Navy Blue */
        background-size: cover;
        object-fit: cover;
    }
    @keyframes blink {
        0% { opacity: 1; }
        50% { opacity: 0; }
        100% { opacity: 1; }
      }
    .whatsapp-button {
        display: flex;
        justify-content: center;
      }
      
      .whatsapp-container a {
        display: flex;
        align-items: center;
        background-color: #25d366; /* WhatsApp green color */
        color: #fff;
        border-radius: 5px;
        padding-inline-end: 1vw;
        padding-inline-start: 1vw;
        text-decoration: none;
        animation: blink 3s infinite; /* Apply the blink animation */
        height: 4vh;
      }
      
      .whatsapp-container a:hover {
        background-color: #128c7e; /* Darker shade of WhatsApp green on hover */
      }
      
      .whatsapp-icon {
        margin-right: 1vw;
        font-size: 1rem;
        font-weight: bold;
      }
      
      .whatsapp-text {
        font-size: 1rem;
        font-weight: bold;
        margin-top: 4vw;
      }
      
      
    .offer-container {
        background-color: #f1c40f; /* Yellow color */
        padding: 10px;
        margin-top: -2vw;
        margin-left: -2vw;
        margin-right: -2vw;
     
    }
    
    /* Styling for the offer text */
    .offer-text {
        margin: 0.6vw;
        color: white; /* White text color */
        font-size: 0.9rem; /* Adjust font size as needed */
        font-weight: bold;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Text shadow with horizontal and vertical offset */
    }
    
    .contenthead {
        color: #f1c40f; /* Accent color */
        font-size: 2rem;
    }
   
    .parahead {
        font-size: 1rem;
        color: white;
        font-weight: bold;
    }
    .heading-container {
        border: 2px solid #dbf243; /* Gray color */
        border-radius: 5px;
        padding: 10px;
        margin-left: 1vh;
        margin-right: 1vh;
        margin-bottom: 20px; /* Adjust margin as needed */
    }
    
    /* Styling for the heading text */
    .heading-text {
        margin: 0; /* Remove default margin */
        color: #ebfe3c; /* Gray color */
        font-size: 0.9rem; /* Adjust font size as needed */
    }
    .blessed-line {
        margin-bottom: 20px;
    }
    
    .blessed-line p {
        color: #ffffff; /* White */
        font-size: 0.8rem;
        font-style: italic;
    }

    .video-row {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
      }
      
      .video-container {
        width: 100%; /* Display each video container in full width */
        margin-top: 2vh;
        height: 30vh;
      }
      
      .video-element {
        width: 100%;
     
      }
      
    .formbutton{
           width: 90%;
      }
    .button-link{
        text-decoration: none ;
        color: white;
        font-weight: bold;
        font-size:1rem;
        padding-bottom:4vw ;
        text-transform: uppercase;
        margin-left: 6vw;
    }
    .call-whatsapp-button-symbol{
        font-size: 2rem;
      }
    .popup-form {
        position: absolute;
        top: 90%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: #f2ecec;
        padding: 20px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        z-index: 9999;
        width: 90%;
        max-width: 300px;
        text-align: left;
    }
    
    .popup-formh2 {
        text-align: center;
        color: #896f07;
        margin: 1vh;
        font-size: 1.5rem !important;
    }
    
    /* Styling for form groups */
    .form-group {
        margin-bottom: 2vh;
    }
    
    /* Styling for form labels */
    .form-label {
        color: #333;
    }
    
    /* Styling for form controls */
    .form-control {
        width: 100%;
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 5px;
    }
    
 
    /* Add hover effect */
    .formbutton:hover {
        background-color: #d2f414; /* Darker Blue */
    }
    .form-select {
        max-width: 9.2vh ; 
    }
    .form-select select  {
        height: 4vh !important;
        font-size: 0.5rem !important; /* Adjust the font size as needed */
    }
    .fbutton {
        margin-left: 2vh;
        font-size: 1rem;
        width: 30vh!important;
        border: none !important;
        background-color: #b79409 !important;
    }

    .fbutton:hover {
        font-size: 1.2rem;
    }

    .popup-formh2 {
        font-size: 2rem;
    }

    .close-btn{
        cursor: pointer !important;
    }
    .connect-with-us {
        background-color:#25d366 !important; /* Yellow */
        color: #ffffff; /* White */
        border: none;
        border-radius: 5px;
        padding: 10px 20px;
        font-size:0.8rem;
        text-transform: uppercase;
        margin-top: 2vh;
        cursor: pointer;
        text-decoration: none;
        margin-bottom: 3vh;
        transition: background-color 0.3s ease;
    }
    
    .connect-with-us:hover {
        background-color: #d4ac0d; /* Darker shade of yellow */
    }
    
}
