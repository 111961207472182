/* Style for the underline */
.gallary{
    background-color: #faf3f3;
}
.photoshead {  
   color:black; /* Secondary color */  
   font-family:poppins;
   font-weight: bold;
   top: 2vw;
  
}

.image-card-container {
    display: flex;
    justify-content: space-around;
    margin-top: 4vw;
}


.image-card {
    position: relative;
    overflow: hidden;
    transition: transform 0.3s ease;
    margin-bottom: 2vw;
}


.card-image {
    width: 30vw;
    height: 30vw;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
.close-btn{
    cursor: pointer !important;
}

.limited-offer-footer {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 20px;
    color: white;
    background-color: rgb(9, 9, 87);
    border-top: 1px solid #ddd;
}

.limited-offer-box {
    display: flex;
    flex-direction: column;
    background-color: #e2b42d;
    width: 50%;
    border-radius: 1vw 1vw 0vw 0vw;
    margin-left: 7vw;
    height: 2vw;
}

.limited-offer-title {
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Shadow properties */
    
}

.limited-offer-timer {
    display: flex;
    border: 2px solid #e2b42d;
    border-radius: 1vw;
    justify-content: space-evenly;
    width: 30vw;
   
}

.timer-section {
    margin-right: 10px; /* Add margin between timer sections */
    display: flex;
    justify-content: space-evenly;
    flex-direction: column; /* Arrange items vertically */
    
}
.timer-section-digit{
    font-size: 2rem;
    font-weight: bold;
}
.timer-section-label{
    font-size: 1rem;

}
.register-button{
    background-color: rgb(197, 16, 16);
    width: 30vw;
    font-size: 2rem;
    font-weight: bold;
    margin: 2vw;
    border: none;
    border-radius: 1vw;

}
.register-bt{
    margin-left: 4vw;
    margin-right: 4vw!important;
}
.Register-button-text{
    margin-left: 1vw;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Shadow properties */
   
}


@media screen and (max-width: 991px){
    .photoshead {
        font-size: 2rem;
        color: rgba(0, 0, 0, 0.912); /* Orange */
        text-align: center;
        position: relative;
    }

    .photoshead::after {
        content: "";
        display: block;
        width: 100px;
        height: 3px;
        background-color: orange; /* Blue */
        position: absolute;
        bottom: -10px;
        left: calc(50% - 45px);
    }
     .gallary{
        background-color:rgb(238, 236, 236);
        width: 100%;
        margin-top: -8vw;
     }
    .image-card-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 10vw;
    }

    .image-card {
        width: 90%;
        margin-bottom: 20px;
        display: flex;
        justify-content: center;
       
    }
    .card-image {
        width: 30vh;
        height: 30vh;
        border-radius: 10px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        transition: transform 0.3s ease;
        object-fit:cover;

    }
    
.limited-offer-footer {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 20px;
    color: white;
    background-color: rgb(9, 9, 87);
    border-top: 1px solid #ddd;
}

.limited-offer-box {
    display: flex;
    flex-direction: column;
    background-color: #e2b42d;
    width: 70%;
    border-radius: 1vw 1vw 0vw 0vw;
    margin-left: 7vw;
    height: 2.5vh;
}

.limited-offer-title {
    font-size: 0.8rem;
    font-weight: bold;
    text-align: center;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Shadow properties */
    
}

.limited-offer-timer {
    display: flex;
    border: 2px solid #e2b42d;
    border-radius: 1vw;
    justify-content: space-evenly;
    width: 25vh;
   
   
}

.timer-section {
    margin-right: 10px; /* Add margin between timer sections */
    display: flex;
    justify-content: space-evenly;
    flex-direction: column; /* Arrange items vertically */
    
}
.timer-section-digit{
    font-size: 0.8rem;
    font-weight: bold;
    margin-left: 2vh;
}
.timer-section-label{
    font-size: 1rem;
    font-size: 0.8rem;
   

}
.register-button{
    background-color: rgb(152, 15, 15);
    width: 30vw;
    font-size: 1rem;
    font-weight: bold;
    margin: 2vw;
    border: none;
    border-radius: 1vw;

}
.register-bt{
    margin-left: 4vh;
   
}
.Register-button-text{
    margin-left: 1vw;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Shadow properties */
   
}
}